$(document).ready(function () {
  let select = $('#field-select')
  let valueField = $('#field-input')
  let firstCheck = true

  if (select.length) {
    buildList()
  }


  $(document).on('change', '#field-select', function () {
    buildList()
  })

  function buildList () {
    let field = select.val()

    $.ajax({
      url: Routing.generate('ticket_field_values'),
      type: 'POST',
      data: {field: field, ticket: pageEntity},
      dataType: 'json',
      success: function (data) {
        valueField.remove()
        field = field.split('.')[1]

        if (data.status) {
          valueField = $('<select id="field-list" name="filterValue"></select>')
          valueField.addClass('form-control')
          valueField.addClass('margin-left-3')
          valueField.append($('<option disabled selected>Select ' + field + '</option>'))

          $.each(data.values, function (index, value) {
            let option = $('<option value="' + value[field] + '">' + value[field] + '</option>')
            valueField.append(option)
            if (selectedValue === value[field]) {
              option.attr('selected', 'selected')
            }
          })
        } else {
          let value = firstCheck ? selectedValue : '';

          valueField = $('<input type="text" id="field-input" value="'+ value +'" name="filterValue" class="form-control margin-left-3" data-toggle="tooltip" data-placement="top" title="" data-original-title="Use * for partial search (*something*, some*, *thing)">')
        }
        select.after(valueField)
        valueField.tooltip()

        if (firstCheck) {
          firstCheck = false
        }
      },
    })
  }
})
