$(document).ready(function () {
  /**
   * Bootstrap tooltip
   */
  $('[data-toggle="tooltip"]').tooltip()

  /**
   * Multiple attachments
   */
  let collections = [
    {holder: '#project_user_attachments', entity: 'project_user'},
    {holder: '#user_attachments', entity: 'user'},
    {holder: '#submittal_attachments', entity: 'submittal'},
    {holder: '#response_attachments', entity: 'response'},
    {holder: '#ticket_email_attachments', entity: 'ticket_email'},
    {holder: '#diary_email_attachments', entity: 'diary_email'},
    {holder: '#rfi_attachments', entity: 'rfi'},
    {holder: '#transmittal_attachments', entity: 'transmittal'},
    {holder: '#standard_diary_attachments', entity: 'standard_diary'},
    {holder: '#diary_response_attachments', entity: 'diary_response'},
    {holder: '#diary_response_edit_attachments', entity: 'diary_response_edit'},
    {holder: '#ct_style_diary_attachments', entity: 'ct_style_diary'},
    {holder: '#inspection_attachments', entity: 'inspection'},
    {holder: '#inspect_comment_attachments', entity: 'inspect_comment'},
    {holder: '#inspection_edit_comment_attachments', entity: 'inspect_edit_comment'}
  ]

  $.each(collections, function(index, collection) {
    let $collectionHolder = $(collection.holder)
    let $addLink = $('<a href="#" id="add-attachment-' + collection.entity + '" class="btn btn-outline-secondary">Add new</a>')

    // Get the element that holds the collection of tags
    if ($collectionHolder){
      // add the "add a tag" anchor and li to the tags ul
      $collectionHolder.append($addLink)

      // count the current form inputs we have (e.g. 2), use that as the new
      // index when inserting a new item (e.g. 2)
      $collectionHolder.data('index', $collectionHolder.find(':input').length)

      // add a delete link to all
      $('> .form-group.row', $collectionHolder).each(function (index) {
        addDeleteLink($('input', $(this)), index, collection.entity)
      })
    }

    $addLink.on('click', function (e) {
      // prevent the link from creating a "#" on the URL
      e.preventDefault()

      // add a new form (see next code block)
      addMediaType($collectionHolder, $addLink, collection.entity)
    })

    //  remove empty file inputs
    $('form.with-attachments').on('submit', function () {
      // let attachments = $('#user_attachments')
      $('>.form-group.row', $collectionHolder).each(function () {
        let fileInput = $('input:file', $(this))

        //  if no file link and file input is empty
        if (!$('a.file-s3-url', $(this)).length && (fileInput.get(0).files.length === 0)) {
          $(this).remove()
        }
      })
    })
  })

  function addMediaType ($collectionHolder, $addLink, entity) {
    // Get the data-prototype explained earlier
    let prototype = $collectionHolder.data('prototype')

    // get the new index
    let index = $collectionHolder.data('index')

    let newForm = prototype

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    newForm = newForm.replace(/__name__/g, index)

    // increase the index with one for the next item
    $collectionHolder.data('index', index + 1)

    // Display the form in the page in an p, before the "Add" link
    $addLink.before(newForm)

    // add a delete link to the new form
    addDeleteLink($('input', $addLink.prev()), index, entity)
  }

  function addDeleteLink (input, index, entity) {
    let $removeFormA = $('<a href="#" class="btn btn-secondary file-s3-remove mb-3 mb-sm-0 mr-sm-4">delete</a>')
    input.before($removeFormA)

    if (index == null) {
      index = 0
    }

    $removeFormA.on('click', function (e) {
      // prevent the link from creating a "#" on the URL
      e.preventDefault()

      $('#row_' + entity + '_attachments_' + index).remove()
    })
  }

  /**
   * Delete confirm
   */
  $(document).on('click', '.delete-confirm', function (e) {
    e.preventDefault();
    let url = $(this).attr('href')
    bootbox.confirm({
      backdrop: true,
      message: "Are you sure you want to delete?",
      buttons: {
        confirm: {
          label: 'Yes',
          className: 'btn-success'
        },
        cancel: {
          label: 'No',
          className: 'btn-danger'
        }
      },
      callback: function (result) {
        if (result) {
          window.location.href = url;
        }
      }
    });
  })

  $(document).on('click', '#file-manager-permissions .form-check-input', function () {
    let checked = $(this).prop('checked')
    $('#file-manager-permissions .form-check-input').each(function () {
      $(this).prop('checked', false)
    })

    if (!checked) {
      $(this).prop('checked', false)
    } else {
      $(this).prop('checked', true)
    }
  })

  $('#maxItemPerPage').change(function(){
    let searchParams = new URLSearchParams(window.location.search)
    let item = $('#maxItemPerPage').find(":selected").text();

    searchParams.delete('page')

    if (searchParams.has('limit')) {
      searchParams.delete('limit')
    }
    searchParams.set('limit', item)

    window.location.href = window.location.origin + window.location.pathname + '?' + searchParams.toString() ;
  })
})
